/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import { ApolloClient, InMemoryCache, fromPromise, ApolloLink } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition, relayStylePagination } from "@apollo/client/utilities";
import { onError } from "@apollo/link-error";

import { createClient } from "graphql-ws";

import MultiAPILink from "startup/MultiAPILink";

import getNewToken from "utils/getNewToken";

function createWsLink() {
  return new GraphQLWsLink(
    createClient({
      url: process.env.REACT_APP_GRAPHQL_API_WS,
      connectionParams: () => ({
        authorization: localStorage.getItem("authToken"),
      }),
    }),
  );
}

let wsLink = createWsLink();

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const { message, location, path, extensions } = err;

      if (message.includes("You do not have access")) {
        if (window) window.location.replace("/app");
      }

      if (message.includes("prisma.supportCalculation.update()")) {
        localStorage.setItem("hasError", "true");
        if (window) window.location.reload();
      }

      if (extensions?.code === "UNAUTHENTICATED") {
        const oldToken = localStorage.getItem("authToken");

        if (oldToken) {
          // eslint-disable-next-line no-loop-func
          return fromPromise(getNewToken()).flatMap((accessToken) => {
            const oldHeaders = operation.getContext().headers;

            if (accessToken) {
              localStorage.setItem("authToken", accessToken);

              wsLink = createWsLink();
            } else {
              localStorage.removeItem("authToken");
            }

            operation.setContext({
              headers: {
                ...oldHeaders,
                Authorization: accessToken,
              },
            });

            return forward(operation);
          });
        }
      }

      const textError = `[GraphQL error]: ${message}, Location: ${location}, Path: ${path}`;

      console.error(textError);
    }
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const multiLink = new MultiAPILink();

const link = ApolloLink.from([
  errorLink,
  ApolloLink.split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    multiLink,
  ),
]);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        documentsConnection: relayStylePagination(["where"]),
      },
    },
  },
});

const client = new ApolloClient({
  link,
  cache,
});

export default client;
