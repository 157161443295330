export const titleCase = (input) => {
  const SMALL_WORDS =
    /\b(?:an?d?|a[st]|because|but|by|en|for|i[fn]|neither|nor|o[fnr]|only|over|per|so|some|tha[tn]|the|to|up|upon|vs?\.?|versus|via|when|with|without|yet)\b/i;
  const WHITESPACE = /\s/;
  const IS_MANUAL_CASE = /.(?=[A-Z]|\..)/;
  const ALPHANUMERIC_PATTERN = /[A-Za-z0-9\u00C0-\u00FF]/;

  let result = "";

  if (input.includes("_") || /^[A-Z]+$/.test(input)) {
    input = input.toLowerCase();
  }

  // Remove underscores from snake case
  input = input.replace(/_/g, " ");

  // Split input by camel case or spaces
  const words = input.split(/(?=[A-Z])|\s+/);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < words.length; i++) {
    const token = words[i];

    if (
      // Ignore already capitalized words.
      !IS_MANUAL_CASE.test(token) &&
      // Ignore small words except at beginning or end.
      (!SMALL_WORDS.test(token) || i === 0 || i === words.length - 1) &&
      // Ignore URLs.
      (i === words.length - 1 ||
        input.charAt(input.indexOf(token) + token.length) !== ":" ||
        WHITESPACE.test(input.charAt(input.indexOf(token) + token.length + 1)))
    ) {
      // Find and uppercase first word character, skips over *modifiers*.
      result += token.replace(ALPHANUMERIC_PATTERN, (m) => m.toUpperCase());
    } else {
      result += token;
    }

    if (i < words.length - 1) {
      // Add whitespace or underscore as separator between words
      if (input.charAt(input.indexOf(token) + token.length) === "_") {
        result += "_";
      } else {
        result += " ";
      }
    }
  }

  return result;
};
