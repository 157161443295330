import gql from "graphql-tag";

const CLIO_INTEGRATION = gql`
  fragment CLIO_INTEGRATION on ClioIntegration {
    id
    addClioClientsToDivorcepath
    addClioDocumentsToDivorcepath
    addDivorcepathClientsToClio
    addDivorcepathDocumentsToClio
    isSuspended
    onDeleteFromClio
    onDeleteFromDivorcepath
    proxyUserId
    frozenAt
  }
`;

export default CLIO_INTEGRATION;
