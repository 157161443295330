import { gql } from "@apollo/client";

const ALL_TEMPLATES = gql`
  query allDocumentTemplate($where: DocumentTemplateFilter) @api(name: "sanity") {
    allDocumentTemplate(where: $where) {
      _id
      name
      number
      description
      deskdivorce
      require_support_calculation
      official_source_url
      official_publication_date
      jurisdiction {
        ... on Province {
          _id
          name
          shorthand
        }
        ... on Country {
          _id
          name
          shorthand
        }
      }
      category {
        _id
        name
      }
      court {
        _id
        name
      }
      courts {
        _id
        name
      }
      sections {
        section
        label
        hidden
        tag
      }
    }
  }
`;

export default ALL_TEMPLATES;
