import React, { Fragment, useEffect, useMemo, useState } from "react";

import { useApolloClient } from "@apollo/client";
import classNames from "classnames";
import { isNil, orderBy } from "lodash";
import { Form } from "reactstrap";

import CLIENT_FRAGMENT from "graphql/fragments/client";
import useUpdateFinancesSupport from "graphql/hooks/client/useUpdateFinancesSupport";

import { options as customScenariosOptions } from "components/calculations/components/AgreedSpousalSupport";
import Modal from "components/common/Modal";
import Select from "components/common/inputs/Select/Select";

import useCreateDocumentFlow from "hooks/useCreateDocumentFlow";
import useModal from "hooks/useModal";

import { formatDate } from "utils/date";
import parseSupportCalculation from "utils/parseSupportCalculation";

export const SELECT_CALCULATION_MODAL_NAME = "SELECT_CALCULATION";

const getCalculations = (apolloClient, clientId, taxYear) => {
  let calculations = [];

  try {
    const client = apolloClient.readFragment({
      id: `Client:${clientId}`,
      fragment: CLIENT_FRAGMENT,
      fragmentName: "CLIENT_FRAGMENT",
    });

    calculations = client?.supportCalculations?.map((calculation) =>
      parseSupportCalculation(calculation),
    );
  } catch (error) {
    console.error(error);
  }

  return orderBy(
    calculations?.filter((c) => c.taxYear === taxYear?.toString()),
    (c) => new Date(c.updatedAt),
    "desc",
  );
};

const getScenarioByType = (type) => customScenariosOptions?.find((o) => o.value === type)?.label;

const getDefaultCalculation = (calculation, calculations) => calculation || calculations[0] || null;
const getDefaultScenario = (scenario, calculation) =>
  scenario || (calculation || {}).showSpousalSupport ? "Mid Spousal" : "No Spousal" || null;

const selectOptions = [
  {
    label: "Low Spousal",
    value: "Low Spousal",
  },
  {
    label: "Mid Spousal",
    value: "Mid Spousal",
  },
  {
    label: "High Spousal",
    value: "High Spousal",
  },
  {
    label: "No Spousal",
    value: "No Spousal",
  },
];

const SelectCalculationModal = () => {
  const {
    options = {},
    close,
    setDisabled,
    isOpen,
    isCreateDocumentFlow,
  } = useModal(SELECT_CALCULATION_MODAL_NAME);

  const apolloClient = useApolloClient();

  const [updateFinancesSupport] = useUpdateFinancesSupport();

  const createDocumentFlow = useCreateDocumentFlow(4, { client: options.client });

  const { clientId, taxYear, calculation, scenario, finances, description } = options;

  const calculations = getCalculations(apolloClient, clientId, taxYear);

  const [selectedCalculation, setSelectedCalculation] = useState(
    getDefaultCalculation(calculation, calculations),
  );

  const [selectedScenario, setSelectedScenario] = useState(
    getDefaultScenario(scenario, selectedCalculation),
  );

  const scenariosOptions = useMemo(() => {
    const supportType = selectedCalculation?.agreedSpousalSupportType;

    if (supportType && supportType !== "standardScenarios" && supportType !== "noSpousal") {
      const supportTypeLabel = getScenarioByType(supportType);

      return [
        ...selectOptions,
        {
          label: supportTypeLabel,
          value: supportTypeLabel,
        },
      ];
    }

    return selectOptions;
  }, [selectedCalculation]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    await updateFinancesSupport({
      clientId,
      finances,
      taxYear,
      scenario: selectedScenario,
      calculationId: selectedCalculation?.id,
    });

    setDisabled(false);

    if (isCreateDocumentFlow) {
      createDocumentFlow(options);
    }

    close();
  };

  useEffect(() => {
    setDisabled(true);

    if (!isNil(selectedScenario) && !isNil(selectedCalculation)) {
      setDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCalculation, selectedScenario, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setSelectedCalculation(getDefaultCalculation(calculation, calculations));
      setSelectedScenario(getDefaultScenario(scenario, selectedCalculation));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setSelectedScenario(
        selectedCalculation?.showSpousalSupport === false
          ? "No Spousal"
          : getDefaultScenario(scenario, selectedCalculation),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, selectedCalculation?.showSpousalSupport]);

  return (
    <Modal
      size="md"
      name={SELECT_CALCULATION_MODAL_NAME}
      form={SELECT_CALCULATION_MODAL_NAME}
      title="Merge Support Calculation"
      submitButtonTitle="Confirm"
      className="select-calculation-modal"
      borderedHeader={false}
      showCloseButton
    >
      <Form id={SELECT_CALCULATION_MODAL_NAME} onSubmit={handleSubmit}>
        <p className="description">{description}</p>

        {!options.hideCalculations && (
          <React.Fragment>
            <p className="description">
              Select a saved support calculation for this client to merge with this client's
              financial profile and use for the current tax year ({taxYear}).
            </p>
            <p className="description">
              Once you have selected the calculation, you'll need to choose a support scenario.
            </p>
            <div
              className={classNames("calculations-selector mb-3", {
                empty: !calculations?.length,
              })}
            >
              {!calculations?.length && (
                <span className="no-calculations">No support calculations</span>
              )}
              {!!calculations?.length && (
                <Fragment>
                  <div className="calculations-selector-header">
                    <div className="header-cell name">Name</div>
                    <div className="header-cell type">Type</div>
                    <div className="header-cell created-at">Updated</div>
                  </div>
                  <div className="calculations-selector-list">
                    {calculations?.map((calculation) => (
                      <div
                        className={classNames("calculation", {
                          active: calculation.id === selectedCalculation?.id,
                        })}
                        onClick={() => setSelectedCalculation(calculation)}
                        key={calculation.id}
                      >
                        <div className="calculation-name">{calculation.title}</div>
                        <div className="calculation-type">
                          {calculation.showSpousalSupport ? "Spousal" : "Child"}
                        </div>
                        <div className="calculation-created-at">
                          {formatDate({ date: calculation.updatedAt, template: "MMM-DD-YYYY" })}
                        </div>
                      </div>
                    ))}
                  </div>
                </Fragment>
              )}
            </div>
          </React.Fragment>
        )}

        <Select
          width="unset"
          label="Support Scenarios"
          hint="Please choose the appropriate Support Scenarios to complete the merge"
          isRequired
          options={scenariosOptions}
          disabled={!selectedCalculation || selectedCalculation?.showSpousalSupport === false}
          placeholder="Select Support Scenario"
          value={selectedScenario}
          onChange={(value) => setSelectedScenario(value.value)}
        />
      </Form>
    </Modal>
  );
};

export default SelectCalculationModal;
