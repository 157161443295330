/* eslint-disable no-console */
import React, { useCallback, useMemo } from "react";

import { useApolloClient, useMutation } from "@apollo/client";
import { FormikProvider, useFormik } from "formik";
import { isEmpty, merge } from "lodash";
import { toast } from "react-toastify";
import { Form } from "reactstrap";
import * as yup from "yup";

import UPDATE_CLIENT from "graphql/mutations/clients/updateClient";
import ALL_COURTS from "graphql/sanity/allCourts";

import difference from "components/calculations/utils/difference";
import Button from "components/common/Button";
import ToggleButtons from "components/common/ToggleButtons";
import CourtLocationSelect from "components/common/inputs/Select/CourtLocationSelect";
import CourtSelect from "components/common/inputs/Select/CourtSelect";
import ProvinceSelect from "components/common/inputs/Select/ProvinceSelect";
import TextInput from "components/common/inputs/TextInput";

import useCourtInformation from "hooks/me/useCourtInformation";

import assignObjectValuesToSet from "utils/assignObjectValuesToSet";

const validationSchema = yup.object().shape({
  provinceShorthand: yup.string().required("Province is required"),
  courtId: yup.string().required("Court is required"),
  courtFile: yup.string().required("Court File is required"),
});

const CourtForm = ({ user, hideToggles, hideFields = [], onSubmit }) => {
  const [updateClient, { loading }] = useMutation(UPDATE_CLIENT);
  const apollo = useApolloClient();

  const {
    courtFile: defaultCourtFile,
    courtId: defaultCourtId,
    courtLocationId: defaultCourtLocationId,
    provinceShorthand: defaultResidence,
  } = useCourtInformation(user?.client);

  const initialValues = useMemo(
    () => ({
      provinceShorthand: defaultResidence || "",
      courtFile: defaultCourtFile || "",
      courtId: defaultCourtId || "",
      courtLocationId: defaultCourtLocationId || "",
      isPlaintiff: user?.client?.isPlaintiff,
      isApplicant: user?.client?.isApplicant,
    }),
    [
      defaultCourtFile,
      defaultCourtId,
      defaultCourtLocationId,
      defaultResidence,
      user?.client?.isApplicant,
      user?.client?.isPlaintiff,
    ],
  );

  const handleSubmitForm = useCallback(
    async (values) => {
      const differenceData = difference(values, initialValues);

      try {
        if (!isEmpty(differenceData)) {
          const { isApplicant, isPlaintiff, ...upsert } = values;

          const cachedData = apollo.readQuery({
            query: ALL_COURTS,
            variables: { where: { province: { shorthand: { eq: values?.provinceShorthand } } } },
          });

          const locationData = cachedData?.allCourt
            .find((court) => court._id === values?.courtId)
            ?.locations.find((location) => location._id === values?.courtLocationId);

          if (locationData) {
            merge(upsert, {
              street1: locationData.street1,
              street2: locationData.street2,
              postal: locationData.postal,
              city: locationData.city?.name,
              province: locationData.province?.name,
              judicialDistrict: locationData.judicialDistrict?.name,
            });
          }

          const variables = {
            data: {
              court: {
                upsert: {
                  create: upsert,
                  update: assignObjectValuesToSet(upsert),
                },
              },
              isPlaintiff: { set: isPlaintiff },
              isApplicant: { set: isApplicant },
            },
            where: {
              id: user?.client?.id,
            },
          };

          await updateClient({ variables });

          toast.success("Court information has been successfully changed");
        }
      } catch (err) {
        console.error(err);
        err.graphQLErrors.map(({ message }) => toast.error(message));
      } finally {
        onSubmit?.(values);
      }
    },
    [initialValues, apollo, user?.client?.id, updateClient, onSubmit],
  );

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmitForm,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema,
  });

  const { setFieldValue, values, handleSubmit } = formik;

  const disabled = loading;

  return (
    <FormikProvider value={formik}>
      <Form className="court-form" onSubmit={handleSubmit}>
        {!hideFields.includes("provinceShorthand") && (
          <ProvinceSelect
            isRequired
            isFormikField
            label="Province"
            disabled={disabled}
            containerClassName="mb-3"
            onChange={({ value }) => {
              setFieldValue("provinceShorthand", value);
              setFieldValue("courtId", "");
              setFieldValue("courtLocationId", "");
            }}
          />
        )}

        {(!hideFields.includes("courtId") || !values.courtId) && (
          <CourtSelect
            label="Select Court"
            containerClassName="mb-3"
            provinceShorthand={values?.provinceShorthand}
            disabled={!values?.provinceShorthand || disabled}
            onChange={({ value }) => {
              setFieldValue("courtId", value);
              setFieldValue("courtLocationId", "");
            }}
            isFormikField
            isRequired
          />
        )}

        {!hideFields.includes("courtLocationId") && (
          <CourtLocationSelect
            label="Court Location"
            containerClassName="mb-3"
            courtId={values?.courtId}
            provinceShorthand={values?.provinceShorthand}
            disabled={!values?.courtId || disabled}
            isFormikField
          />
        )}

        {!hideFields.includes("courtFile") && (
          <TextInput
            name="courtFile"
            label="Court File Number"
            placeholder="Court File Number"
            containerClassName="mb-4"
            hint="If this is a new court action, enter a blank space as a placeholder"
            disabled={disabled}
            isRequired
          />
        )}

        {!hideToggles && (
          <React.Fragment>
            <ToggleButtons
              label="Is Client Plaintiff?"
              name="isPlaintiff"
              buttons={[
                {
                  value: true,
                  label: "Plaintiff",
                },
                {
                  value: false,
                  label: "Defendant",
                },
              ]}
              disabled={disabled}
              className="mt-3"
            />
            <ToggleButtons
              label="Is Client Applicant?"
              name="isApplicant"
              buttons={[
                {
                  value: true,
                  label: "Applicant",
                },
                {
                  value: false,
                  label: "Respondent",
                },
              ]}
              disabled={disabled}
              className="mt-3"
            />
          </React.Fragment>
        )}

        <div className="d-flex justify-content-between align-items-end buttons">
          <Button type="submit" size="lg" leftFAIcon="check" disabled={disabled}>
            Save & Submit
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default CourtForm;
