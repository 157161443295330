import React, { useMemo, useState } from "react";

import classNames from "classnames";
import { Collapse } from "reactstrap";

import Icon from "components/common/Icon";

const nodeIndentsMap = {
  0: 0,
  1: 16,
  2: 32,
};

const TreeNode = ({ id, title, nodes, nestingLevel, onCheckboxChange, checked, disabled }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = () => setIsOpen((prev) => !prev);

  const isLeaf = !nodes?.length && nestingLevel !== 0;

  const marginLeft = useMemo(() => {
    if (!nestingLevel) return 0;

    if (typeof nodeIndentsMap[nestingLevel] === "number") return nodeIndentsMap[nestingLevel];

    return nestingLevel - 1 * 32;
  }, [nestingLevel]);

  const handleOnChange = async () => {
    if (!onCheckboxChange) return;

    await onCheckboxChange(id, !checked);
  };

  if (!isLeaf) {
    return (
      <div
        className={classNames("tree-node tree-node-collapse", {
          "parent-node": nestingLevel === 0,
        })}
        style={{ marginLeft }}
      >
        <div className="tree-node-title" onClick={toggleCollapse}>
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input"
              name="askStory"
              id={title}
              onChange={handleOnChange}
              disabled={disabled}
              type="checkbox"
              checked={checked}
              label={title}
            />
            <label className="custom-control-label" htmlFor={title}>
              <span>{title}</span>
            </label>
          </div>
          <Icon name={`arrow-${isOpen ? "up" : "down"}`} />
        </div>
        <Collapse isOpen={isOpen}>
          <div className="collapse-content">
            {(nodes || []).map((node) => (
              <TreeNode key={node.key} {...node} onCheckboxChange={onCheckboxChange} />
            ))}
          </div>
        </Collapse>
      </div>
    );
  }

  return (
    <div className="tree-node node-leaf" style={{ marginLeft }}>
      <div className="tree-node-title" onClick={toggleCollapse}>
        <div className="custom-control custom-checkbox">
          <input
            className="custom-control-input"
            name="askStory"
            id={title}
            onChange={handleOnChange}
            disabled={disabled}
            type="checkbox"
            checked={checked}
            label={title}
          />
          <label className="custom-control-label" htmlFor={title}>
            <span>{title}</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default TreeNode;
