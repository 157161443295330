/* eslint-disable react/jsx-no-useless-fragment */
import React, { Fragment, useEffect, useState } from "react";

import CustomHelmet from "components/calculations/components/CustomHelmet";
import Modal from "components/common/Modal";

import useModal from "hooks/useModal";

import { titleCase } from "helpers/titleCase";

import TreeNode from "./TreeNode";

export const DOCUMENT_SECTIONS_MODAL_NAME = "CLIENT_DOCUMENT_SECTIONS";

const convertTreeToNestedFormat = (tree) => {
  function processNodes(nodes) {
    if (!nodes) return [];

    return nodes.flatMap((node) => [
      { section: node.id, hidden: !node.checked },
      ...processNodes(node.nodes),
    ]);
  }

  return processNodes(tree.nodes);
};

const generateTree = (args) => {
  const { sections = [] } = args;

  const parseSectionName = (bookmarkSection) => {
    const { label, section, tag } = bookmarkSection;

    const numbers = section.split(".").map((s) => Number.parseInt(s, 10));

    return {
      section,
      label,
      numbers,
      tag,
    };
  };

  const parsedSections = sections.map((bn) => parseSectionName(bn));

  const genLevelNodes = (level, current) => {
    const filteredSections = parsedSections.filter(
      (b) => b.numbers.length === level + 1 && current.every((num, idx) => b.numbers[idx] === num),
    );

    if (filteredSections.length === 0) return null;

    const isRoot = level === 0;

    const nodes = filteredSections.map((sectionInfo) => {
      const { label, numbers, section } = sectionInfo;

      const childNodes = genLevelNodes(level + 1, numbers);

      const title = (() => {
        const isNumeralSection = section.replace(/[0-9.]/g, "").length === 0;

        if (isNumeralSection) {
          return `${section}${isRoot ? "." : ""} ${titleCase(label)}`;
        }

        return `${titleCase(label)}`;
      })();

      const node = {
        title,
        checked: !(isRoot && !childNodes),
        key: label,
        id: section,
        nestingLevel: level,
        nodes: childNodes,
        className: isRoot ? "parent-node" : undefined,
      };

      return node;
    });

    return nodes;
  };

  const nodes = genLevelNodes(0, []);

  return {
    nodes,
  };
};

const ClientDocumentsSectionsModal = () => {
  const { options } = useModal(DOCUMENT_SECTIONS_MODAL_NAME);

  const { sections, submit: createDocument } = options || {};

  const [tree, setTree] = useState([]);

  useEffect(() => {
    if (sections) {
      setTree(generateTree({ sections: sections || [] }));
    }
  }, [sections]);

  const onChange = async (key, val) => {
    const parseKeyToNumbers = (key) => key.split(".").map((s) => Number.parseInt(s, 10));

    const keyNumbers = parseKeyToNumbers(key);

    const setNode = (node) => {
      const nodeNumbers = parseKeyToNumbers(node.id || "");

      const isNested = keyNumbers.every((num, idx) => nodeNumbers[idx] === num);

      const updatedNode = {
        ...node,
        checked: isNested ? val : node.checked,
        nodes: node.nodes?.map((n) => setNode(n)),
      };

      if (!isNested && node.nodes) {
        const isAnyChildChecked = node.nodes.some((n) => n.checked);
        updatedNode.checked = isAnyChildChecked;
      }

      return updatedNode;
    };

    const updateParentNodeState = (node) => {
      if (node.nodes) {
        const updatedNodes = node.nodes.map(updateParentNodeState);
        const isAnyChildChecked = updatedNodes.some((n) => n.checked);
        if (isAnyChildChecked) {
          node.checked = true;
        }
        node.nodes = updatedNodes;
      }
      return node;
    };

    const updatedTree = setNode(tree);

    setTree(updateParentNodeState(updatedTree));
  };

  const handleSubmit = () => {
    const sections = convertTreeToNestedFormat(tree);

    createDocument(sections);
  };

  return (
    <Modal
      size="md"
      name={DOCUMENT_SECTIONS_MODAL_NAME}
      noHeader
      onSubmitClick={handleSubmit}
      submitButtonTitle="Confirm"
      className="client-document-sections-form"
      showCloseButton
    >
      <CustomHelmet
        title="Document Sections"
        description="Document Sections"
        href="https://www.divorcepath.com/app/[new-document]"
        isCanonical
      />
      <Fragment>
        <div className="tree-wrapper">
          {tree.nodes?.map((node) => (
            <TreeNode checked={node.checked} key={node.key} onCheckboxChange={onChange} {...node} />
          ))}
        </div>
      </Fragment>
    </Modal>
  );
};

export default ClientDocumentsSectionsModal;
